// All blocks
.block {
  background-position: center;
  background-size: cover;
  padding: 25px 0;
  ul{
    li{
      line-height: 1.7em;
    }
  }
  ol{
    li{
      line-height: 1.7em;
      margin-bottom: 1.1em;
    }
  }
}



// Open content block
.openContent {
  padding: 25px 0;
  p.text-center{
    max-width: 815px;
    margin-left: auto;
    margin-right: auto;
  }
  iframe{
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
}


//featuredContent
.specialFeaturedContent{
  border-top: 24px solid $secondary-color;
  padding: 0;
  .ft-content{
    margin: 30px auto;
    padding: 0 45px;
  }
  h6{
    font-size: 2em;
  }
  .row{
    .col-12:first-of-type{
      display: flex;
      img{
        align-self: center;
      }
    }
  }

}

.featuredContent{
  padding: 50px 0;
}


//infoChart
.infoChart{
  h3{
    text-align: center;
    color: $secondary-color;
    font-size: 3em;
    padding: 20px 0;
  }
  .info-block{
    text-align: center;
    img{
      display: block;
      margin: 10px auto;
      max-width: 100px;
    }
    p{
      font-size: 1.3em;
    }
  }
  .arrow-img{
    img{
      max-width: 65px;
      display: block;
      margin: auto;
    }
    &:last-of-type{
      display: none;
    }
  }

  
}

//contentGrid
.contentIconGrid{
  @include media-breakpoint-down(sm) {
    .grid {
      margin: 15px 0;
    }
  }
  h3{
    text-align: center;
    color: $secondary-color;
    font-size: 2em;
    text-transform: uppercase;
    padding: 20px 0;
  }
  img{
    @extend .img-fluid;
  }

  .border{
    padding: 30px 15px 0;
    position: relative;
    p{
      font-size: 1.3em;
    }
  }
}

//forwardPath
.forwardPath{
  padding-bottom: 25px;
  *{
    @include transition-in-out(0.3s);
  }
  .fw-block{
    display: block;
    margin: 15px 0;
    padding: 15px;
    background: $secondary-color;
    max-width: 400px;
    @include hover-focus {
      text-decoration: none;
      *{
        @include transition-in-out(0.3s);
      }
      .fw-image{
        //mix-blend-mode: luminosity;
        @include transition-in-out(0.3s);
      }
      .btn-primary{
        background: $primary-color;
        color: #fff;
        &:before{
          height: 800%;
          @include transition-in-out(0.3s);
          transform: translate(-27%, 0%) rotate(45deg);
        }
      }
    }
  }
  .fw-image-box{
    display: block;
    position: relative;
    .fw-image{
      width: 100%;
      @include transition-in-out(0.3s);
    }
    h4{
      width: 100%;
      color: #fff;
      font-size: 1.2em;
      text-transform: uppercase;
      padding: 34px 0 0px;
      margin: 0;
      text-align: center;
      @include transition-in-out(0.3s);
    }
  }
  .fw-content{
    display: block;
    padding: 15px 10px 25px;
    color: #fff;
    text-align: center;
    .btn-primary{
      display: inline-block;
      margin: 0 auto;
    }
  }

}



//FAQ
.accordion {
  .card {
    border: none;
    border-radius: 0;
    .card-header {
      &:first-child {
        border-radius: 0;
      }
      @include transition-in-out(0.3s);
      background: lighten($secondary-color, 30%);
      padding:0;
      &:hover {
        @include transition-in-out(0.3s);
        background: $secondary-color;
      }
      a {
        width: 100%;
        display: block;
        color: #fff;
        padding: .75rem 1.25rem;
        &:before {
          content: "+";
          display: inline;
          margin: 0 9px 0 0;
        }
      }
    }
  }
}

// Form block
.block-form {
  form {
    input[type="submit"], button[type="submit"] {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}

//Call to action
.callToAction{
  background-size: cover;
  /*.row{
    @include media-breakpoint-up(md) {
      min-height: 520px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 620px;
    }
  }*/
  .cta-content{
    //border: 2px solid #fff;
    border-radius: 20px;
    padding: 25px 15px ;
    margin: 0 8%;
    //background: rgba(0,0,0, 0.5);
    h1, h2, h3, h4, h5, p{
      color: #fff;
    }
    h2{
      font-size: 2.4em;
      text-transform: uppercase;
    }

    .btn{
      border: 1px solid #fff;
      display: block;
      margin: 40px auto 0;
      max-width: 200px;
      @include hover-focus {
        border: 1px solid #fff;
      }
    }
  }
}


.contentGrid{
  .grid{
    margin: 0 0 45px 0;
  }
  .grid-images{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
  .gridimage{
    position: relative;
    display: block;
    width: 100%;
    max-width: 250px;
    max-height:150px;
    overflow: hidden;
    border: 1px solid #fff;
    margin: 0 1em 0 0;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
    .overlay{
      position: absolute;
      bottom: 0 ;
      right: 0;
      background: rgba($brand-primary, 1);
      transition: 0.3s all ease;
      i{
        padding: 10px;
        color: #fff;
        transition: 0.3s all ease;
      }
    }
    &:hover{
      border: 1px solid lighten($secondary-color, 60%);
      .overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        background: lighten($secondary-color, 20%);
        transition: 0.3s all ease;
        i {
          transition: 0.3s all ease;
        }
      }
    }
  }
}
