.navbar-brand {
  margin: 0;
  padding: 15px 0;
}

.navbar-nav > li > a, .dropdown-menu > li > a {
  font-size: 1em;
}

.navbar-nav > li > a {
  &.dropdown-toggle {
    span.caret {
    }
  }
}

.navbar-nav > li > ul.dropdown-menu {
  text-align: center;
  li.nav-item {
    width: 100%;
    padding: 2px 15px;
    &:hover, &:active, &:focus {
    }
    a {
      color: $primary-color;
      &:hover, &:active {

      }
    }
  }
}

