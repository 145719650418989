#footer{
  padding: 25px 0 10px;
  background: $secondary-color;
  color: #fff;
  text-align: left;
  h4{
    color: #fff;
    font-size: 1.2em;
    border-bottom: 1px solid $brand-primary;
    padding: 0 0 14px;
    margin: 0 0 18px 0;
  }
  a{
    color: #fff;
    @include hover-focus {
      color: $brand-primary;
    }
  }
  p:last-of-type{
    margin: 0;
  }
}

//contact-us
.contact-us{
  margin-top: 35px;
  p{
    margin-bottom: 0.6em;
  }
}

//SOCIAL ICONS
.social-icons{
  ul{
    margin: 20px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid $brand-primary;
    display: flex;
    justify-content: flex-start;
  }
  li{
    display: inline-block;
    list-style: none;
    a{
      margin: 0 10px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;
      text-align: center;
      background: #fff;
      @include transition-in-out(0.2s);
      i{
        font-size: 1.7em;
        position: absolute;
        left: 0;
        right: 0;
        line-height: 1.6em;
        color: $secondary-color;
        @include transition-in-out(0.2s);
      }
      @include hover-focus {
        @include transition-in-out(0.2s);
        i{
          color: $brand-primary;
          @include transition-in-out(0.2s);
        }
      }
    }
  }

}

.copy-text{
  padding: 25px 15px 0 ;
  p{
    font-size: 0.8em;
    margin: 0 0 10px 0;
    a{
      padding: 0 10px 0 0;
    }
  }
}