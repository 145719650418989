//transition mixin
@mixin transition-in-out($timeing) {
  transition: all $timeing ease-in-out;
}

// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #7cb638;

// Colors
$primary-color:   $brand-primary;
$secondary-color: #333333;
$tertiary-color:  #f5f4f5;

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Typography
//fonts
$font-size-base: 1rem;
$font-family-base: 'Montserrat', sans-serif;
$font-size-h1: 3.5em;
$font-size-h2: 2.8em;
$font-size-h3: 2.1em;
$font-size-h4: 1.8em;
$font-size-h5: 1.3em;


$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10);
$link-hover-decoration: underline;

$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1380px
);

// Bootstrap nav
$navbar-height: 5em;
$navbar-collapse-max-height: 12.5em;
$navbar-default-color: #fff;
$navbar-default-bg: #fff;
$navbar-default-link-color: #000;
$navbar-default-link-active-color: $brand-primary;
$navbar-default-link-active-bg: #fff;
$navbar-default-link-hover-color: $brand-primary;
$navbar-default-link-hover-bg: #fff;
$nav-link-padding: 10px 15px;
$grid-float-breakpoint: 75em; // Change when the navbar breaks into mobile view

// Bootstrap buttons
$btn-border-radius-base: 0;
$btn-default-color: $brand-primary;
$btn-default-bg: $brand-primary;
$btn-default-border: $brand-primary;
$btn-primary-color: $brand-primary;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $brand-primary;