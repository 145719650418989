html {
  -webkit-font-smoothing: antialiased;
}
html, body {
  overflow-x: hidden;
}

//titles
h1{
  font-weight: 700;
}
h1, h2, h4 {
  color: $secondary-color;
}
h3{
  color: $brand-primary;
}


//global links
a{
  color: $brand-primary;
  @include transition-in-out(0.3s);
  @include hover-focus {
    @include transition-in-out(0.3s);
    color: $secondary-color;
    text-decoration: none;
  }
}

.icon-box{
  display: flex;
  margin: 0 auto 20px;
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background: $brand-primary;
  color: #fff;
  justify-content: center;
  flex-flow: column;
  i{
    font-size: 3em;
    line-height: 2.3em;
  }
  img{
    width: 70%;
    display: block;
    margin: 0 auto;
  }
}


hr{
  max-width: 680px;
  border-top: 1px solid $brand-primary;
  margin: 22px auto;
  &.bold{
    border-top: 10px solid $brand-primary;
  }
}

