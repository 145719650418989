
// Custom padding and font sizes
.btn{
    white-space: normal;
    border-radius: 0;
    color: #fff;
    z-index: 1;
    position: relative;
    overflow: hidden;
    background: $secondary-color;
    box-shadow: inset 0px 0px 200px 20px $secondary-color;
    border: solid 2px $primary-color;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 0.7em 1.4em;
    &:before{
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: $brand-primary;
      width: 200%;
      height: 0%;
      @include transition-in-out(0.5s);
      transform: translate(-27%, 0%) rotate(45deg);
    }
    @include hover-focus {
      background: lighten($secondary-color, 20%);
      color: #fff;
      border: solid 2px $primary-color;
      &:before{
        height: 800%;
        @include transition-in-out(0.3s);
        transform: translate(-27%, 0%) rotate(45deg);
      }
    }
}



.btn-primary{
  @extend .btn;
  @include hover-focus {
       
    }
}

.btn-default{
  @extend .btn;
  color: #fff;
  box-shadow: inset 0px 0px 100px 20px $primary-color;
  @include hover-focus {
    &:before{
      background: $secondary-color;
    }
  }
}

.btn-lg{
  font-size: 1.9rem;
}

.btn-header{
  font-size: 0.8em;
  padding: 1em 2.7em;
  border: solid 2px $primary-color;
  color: $primary-color;
  @include hover-focus {
    border: solid 2px $primary-color;
    &:before{
      background: $primary-color;
    }
  }
}

.btn-white{
  font-size: 1.2em;
  border: solid 2px #fff;
  box-shadow: inset 0px 0px 200px 20px #fff;
  font-weight: 700;
  padding: 0.7em 2em;
}

.btn-green{
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.7em 2em;
  color: #fff;
  box-shadow: inset 0px 0px 200px 20px $primary-color;
  @include hover-focus {
    &:before{
      background: $secondary-color;
    }
  }
}

button.form-control{
  @extend .btn;
  max-width: 200px;
  color: #fff;
  border-radius: 0;
  border: solid 2px $primary-color;
}
//transition for buttons
.btn-default, .btn-primary, .btn-lg, .button-variant{
		@include transition-in-out(0.3s);
    &:before, &:after{
      @include transition-in-out(0.3s);
    }
	&:hover, &:active {
		@include transition-in-out(0.3s);
    &:before, &:after{
      @include transition-in-out(0.3s);
    }
	}
}
