.menuIcon {
  vertical-align:middle;
  margin:0 2px;
  display:inline-block;
  width: 35px;
  height: 35px;
  position: relative;
  top: -2px;
  background-image: url('/dist/images/menu.svg');
}
.navbar{
  background: $secondary-color;
}

//MAIN LOGO

.navbar-brand{
  margin: auto;
  display: block;
  padding: 0;
  img{
    margin: auto;
    display: block;
    max-width: 200px;
    width: 100%;
  }
}


//CALL TO ACTION BUTTONS
.btn-wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  a {
    margin: 5px 10px;
  }
  @include media-breakpoint-up(lg) {
    position: relative;
    justify-content: flex-end;
    order: 1;
    a {
      margin: 5px 0 0 24px;
    }
  }
}



//NAV BAR
.menu-bar{
  background: $primary-color;
  margin: 0 -16px;
  min-height:40px;
}
@include media-breakpoint-up(lg) {
  .navbar-toggleable-md {
    display: flex;
  }
}
.mobile-nav{
  text-align: right;
  .navbar-toggler{
    border: none;
    text-transform: uppercase;
    margin: 8px 0 0;
    small{
      color: #fff;
      font-family: $font-family-base;
      font-size: 1em;
      font-weight: 700;
    }
  }
}


//phone bar
.phonebar{
  background: $brand-primary;
  text-align: right;
  padding: 10px;
  a{
    color: #fff;
  }
}



.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  width: 100%;
  .nav-item {
    position: relative;
    &.active {
      .nav-link {
        color: $primary-color;
        @include hover-focus {
          color: $primary-color;
        }
      }

    }
    .nav-link {
      color: #fff;
      font-size: 1.1em;
      font-weight: 700;
      padding: 8px 10px;
      margin: 0;
      white-space: nowrap;
      text-align: center;
      @include hover-focus {
        color: $primary-color;
      }
      &.dropdown-toggle:after {
        display: none;
      }
    }
  }
  &.navbar-nav {
    .dropdown-menu {
      border-radius: 0;
      border: 0;
      margin: 0;
      padding: 0;
      background: $secondary-color;
      li.nav-item {
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: 0.9em;
        border-bottom: 1px solid $secondary-color;
        &:last-of-type {
          border-bottom: none;
        }
        .nav-link {
          border-bottom: none;
          @include hover-focus {
            color: #fff;
            border-bottom: none;
            background: $secondary-color;
          }
        }
      }
    }
  }
}